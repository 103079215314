import { AbstractControl, ValidationErrors } from '@angular/forms';

export const PasswordStrengthValidator = (control: AbstractControl): ValidationErrors | null => {
    try {
        const value: string = control.value || '';
        if (!value) {
            return null;
        }
        const letterCharacters = /[A-Za-z]+/g;
        const numberCharacters = /[0-9]+/g;
        if (letterCharacters.test(value) === false || numberCharacters.test(value) === false) {
            return {
            passwordStrength: 'Password must contain at least two of the following: numbers, lowercase letters, uppercase letters'
            };
        } else {
            return null;
        }
    } catch (error) {
      console.error(error);
      throw(error);
    }
};
